import React from "react";
import Title from "../../components/title";

import styles from "./styles.module.scss";
import NavBar from "../navbar";
const Layout = (props: React.PropsWithChildren) => {
  return (
    <div className={styles.app}>
      <NavBar />
      <Title />
      {props.children}
    </div>
  );
};

export default Layout;
