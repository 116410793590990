import React from "react";
import styles from "./styles.module.scss";
import logo from "../../assets/logo.jpeg";

const Logo = () => {
  return (
    <div className={styles.logo}>
      <img src={logo} alt="logo" />
      <span>Holos Technology</span>
    </div>
  );
};

export default Logo;
