export const infer = (event: any) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    for (let i = 0; i < event.target.files.length; i++) {
      formData.append("audios", event.target.files[i]);
    }
    fetch(`/api/infer`, {
      method: "POST",
      body: formData,
    })
      .then(async (res) => {
        
        const blob = await res.blob();

        const file = new File([blob], "output", {});

        // download file
        const a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = `${file.name}.wav`;
        a.click();
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
