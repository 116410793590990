import { infer } from "../../helpers/quickfish";
import { useInstance } from "../../hooks";
import React from "react";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
const QuickFish = () => {
  const { isRunning } = useInstance();
  const [isRendering, setIsRendering] = React.useState(false);

  if (!isRunning) return null;

  const handleFile = async (e: any) => {
    try {
      setIsRendering(true);
      await infer(e);
      setIsRendering(false);
    } catch (error) {
      console.log(error);
    } finally {
      e.target.value = null;
      setIsRendering(false);
    }
  };

  if (isRendering) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <div className={styles.text}>
            Renderizando audio, por favor espere...
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div>
        <Button component="label" variant="contained">
          Inferir Audio
          <input type="file" id="audios" multiple hidden onChange={handleFile} />
        </Button>
      </div>
    </div>
  );
};

export default QuickFish;
