import React from "react";
import styles from "./styles.module.scss";
const Title = () => {
  return (
    <div className={styles.title}>
      <h1>IA Voices</h1>
    </div>
  );
};

export default Title;
