import React from 'react';
import { ThemeProvider } from "@emotion/react";
import Layout from "./components/layout";
import QuickFish from "./components/quickfish";
import { InstanceProvider } from "./contexts/instance";

import { createTheme } from "@mui/material/styles";
// import { QuickFishProvider } from "./contexts/quickfish";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <InstanceProvider>
        {/* <QuickFishProvider> */}
          <Layout>
            <QuickFish />
          </Layout>
        {/* </QuickFishProvider> */}
      </InstanceProvider>
    </ThemeProvider>
  );
}

export default App;
