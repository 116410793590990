import React from 'react';
import { useInstance } from "../../hooks";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Button } from "@mui/material";
const InstanceStatus = () => {
  const { isRunning, handleChangeState } = useInstance();

  return (
    <div className={styles.status}>
      <Button
        className={classNames({
          [styles.button]: true,
          [styles.start]: !isRunning,
          [styles.stop]: isRunning,
        })}
        onClick={handleChangeState}
        color="warning"
      >
        {isRunning ? "Detener" : "Encender"}
      </Button>
    </div>
  );
};

export default InstanceStatus;
