import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getStatus = async () => {
  const response = await axios.get(`${BASE_URL}/status`);
  return response.data;
};

export const startInstance = async () => {
  const response = await axios.post(`${BASE_URL}/start`);
  return response.data;
};

export const stopInstance = async () => {
  const response = await axios.post(`${BASE_URL}/stop`);
  return response.data;
};
