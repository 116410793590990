import React from "react";
import { getStatus, startInstance, stopInstance } from "../helpers/instance";

interface IInstanceContext {
  isRunning: boolean;
  isStarting: boolean;
  handleChangeState: () => void;
}

const InstanceContext = React.createContext<IInstanceContext>({
  isRunning: false,
  isStarting: false,
  handleChangeState: () => {},
});

const InstanceProvider = (props: React.PropsWithChildren) => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [isStopping, setIsStopping] = React.useState(false);
  const [isStarting, setIsStarting] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const response = await getStatus();
      setIsRunning(response.isRunning);
      setIsStopping(response.isStopping);
      setIsStarting(response.isStarting);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      while (isStopping || isStarting) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const response = await getStatus();
        setIsRunning(response.isRunning);
        setIsStopping(response.isStopping);
        setIsStarting(response.isStarting);
      }
    })();
  }, [isStopping, isStarting]);

  const handleChangeState = async () => {
    if (!isRunning) {
      await startInstance();
    } else {
      await stopInstance();
    }

    const response = await getStatus();
    setIsRunning(response.isRunning);
    setIsStopping(response.isStopping);
    setIsStarting(response.isStarting);
  };

  return (
    <InstanceContext.Provider
      value={{ isRunning, isStarting, handleChangeState }}
    >
      {props.children}
    </InstanceContext.Provider>
  );
};

export { InstanceContext, InstanceProvider };
