import React from 'react';
import { AppBar, Box, Toolbar } from "@mui/material";
import Logo from "../logo";
import InstanceStatus from "../status";

export default function NavBar() {
  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Logo />
          <InstanceStatus />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
